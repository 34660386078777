.job {
  position: relative;
  &__back {
    position: absolute;
    top: 0;
    left: 0;
    @media (max-width: 1025px) {
      position: relative;
      max-width: 670px;
      margin: 0 auto 24px;
    }
    button {
      background: #ffffff !important;
      box-shadow: var(--card-box-shadow) !important;
      color: var(--field-label-color);
      padding: 18px 20px 13px;
      font-family: "TTCommons-Regular";
      min-height: 50px;
      width: auto;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      border-radius: 100px;
      text-transform: initial;
      &:hover {
        box-shadow: var(--card-box-shadow-hover) !important;
      }
      img {
        margin: -1px 6px 0 0;
        width: 8px;
        vertical-align: middle;
        transform: rotate(180deg);
      }
    }
  }
  &__details {
    max-width: 670px;
    margin: 0 auto;
    &__title {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      &__heading {
        margin: 2px 0 0 0;
        font-family: "TTCommons-Regular";
        font-size: 28px;
        line-height: 28px;
        color: var(--primary-dark-color);
        font-weight: 600;
      }
      &__save-job {
        width: 91px;
        height: 33px;
        position: relative;
        min-width: fit-content;
        button {
          width: 100% !important;
          height: 100% !important;
          border-radius: 25px;
          background: #ffffff !important;
          box-shadow: var(--card-box-shadow) !important;
          text-transform: initial;
          padding: 0px 10px !important;
          &:hover {
            box-shadow: var(--card-box-shadow-hover) !important;
          }
          > div {
            width: 100% !important;
            min-width: 100% !important;
          }
        }
        &__content {
          display: flex;
          align-items: center;
          gap: 6px;
          &__image {
            > div {
              > div {
                width: 15px !important;
                height: 15px !important;
                a {
                  min-width: 15px !important;
                  img {
                    width: 15px !important;
                    height: 15px !important;
                  }
                }
              }
            }
          }
          &__text {
            margin: 4px 0 0 0;
            color: var(--field-label-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            line-height: 17px;
          }
        }
      }
    }
    &__middle {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      gap: 15px;
      &__left {
        flex-grow: 1;
        &__company {
          margin: 0 0 0 0;
          font-family: "TTCommons-Medium";
          font-size: 22px;
          line-height: 24px;
          color: var(--primary-dark-color);
        }
        &__details {
          margin-top: 14px;
          &__line {
            margin: 0 0 10px 0;
            display: flex;
            &__header {
              display: inline-block;
              min-width: 80px;
              font-family: "TTCommons-Regular";
              font-size: 15px;
              line-height: 19px;
              color: var(--gray-text-color);
            }
            &__data {
              display: inline-block;
              font-family: "TTCommons-Regular";
              font-size: 18px;
              line-height: 19px;
              color: var(--primary-dark-color);
            }
          }
        }
      }
      &__right {
        min-width: 156px;
      }
    }
    &__visa {
      &__heading {
        margin: 2px 0 6px 0;
        font-family: "TTCommons-Medium";
        font-size: 22px;
        line-height: 24px;
        color: var(--primary-dark-color);
      }
      &__body {
        display: flex;
        align-items: center;
        gap: 10px;
        &__header {
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 19px;
          color: var(--gray-text-color);
          margin: 4px 0 0 0;
        }
        &__data {
          font-family: "TTCommons-Regular";
          font-size: 18px;
          line-height: 16px;
          color: var(--link-color);
          border-bottom: 2px solid var(--link-color);
          margin: 4px 4px 0 0;
          cursor: pointer;
        }
        &__tag {
          background: #f1f4f7;
          border-radius: 12.5px;
          height: 25px;
          width: 139px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            display: inline-block;
            margin-top: 2px;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            color: var(--primary-dark-color);
          }
        }
      }
    }
    &__ai-summary {
      margin-top: 15px;
      &__header {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          height: 16px;
          margin-top: -5px;
        }
        p {
          margin: 0 0 0 0;
          font-family: "TTCommons-Medium";
          font-size: 22px;
          line-height: 22px;
          color: var(--primary-dark-color);
        }
      }
      &__body {
        margin: 6px 0 0 0;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        line-height: 21px;
        color: var(--primary-dark-color);
        h3 {
          color: var(--primary-dark-color) !important;
          font-family: "TTCommons-DemiBold" !important;
          font-size: 22px !important;
          font-weight: bold !important;
          letter-spacing: 0 !important;
          line-height: 20px !important;
          margin: 0 0 12px 0 !important;
          padding-top: 4px;
          + p {
            margin-bottom: 25px !important;
            @media (max-width: 767px) {
              margin-bottom: 16px !important;
            }
          }
          span {
            color: var(--primary-dark-color) !important;
            font-family: "TTCommons-Medium" !important;
            font-size: 22px !important;
            font-weight: 500 !important;
            letter-spacing: 0 !important;
            line-height: 24px !important;
            text-decoration: none !important;
            strong {
              font-family: "TTCommons-DemiBold";
            }
          }
        }
        p {
          color: var(--primary-dark-color) !important;
          font-family: "TTCommons-Regular" !important;
          font-size: 18px !important;
          font-weight: 400 !important;
          letter-spacing: 0 !important;
          line-height: 20px !important;
          text-align: left !important;
          margin: 10px 0 0 0;
          strong {
            font-family: "TTCommons-Medium";
            font-weight: 500;
          }
          + p {
            margin-top: 15px !important;
          }
          div {
            margin: 0px !important;
            color: var(--primary-dark-color) !important;
            font-family: "TTCommons-Regular" !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            letter-spacing: 0px !important;
            line-height: 20px !important;
            text-align: left !important;
          }
          span {
            margin: 0px !important;
            color: var(--primary-dark-color) !important;
            font-family: "TTCommons-Regular" !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            letter-spacing: 0px !important;
            line-height: 20px !important;
            text-align: left !important;
            div {
              margin: 0px !important;
              color: var(--primary-dark-color) !important;
              font-family: "TTCommons-Regular" !important;
              font-size: 18px !important;
              font-weight: 400 !important;
              letter-spacing: 0px !important;
              line-height: 20px !important;
              text-align: left !important;
              width: 100% !important;
            }
          }
          pre {
            margin: 0px !important;
            color: var(--primary-dark-color) !important;
            font-family: "TTCommons-Regular" !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            letter-spacing: 0px !important;
            line-height: 20px !important;
            text-align: left !important;
            max-height: initial !important;
            padding: 0 !important;
            + pre {
              margin-top: 20px !important;
            }
          }
        }
        ul {
          margin: 0 !important;
          color: var(--primary-dark-color) !important;
          font-family: "TTCommons-Regular" !important;
          font-size: 18px !important;
          font-weight: 400 !important;
          letter-spacing: 0 !important;
          line-height: 20px !important;
          list-style: none !important;
          margin-bottom: 25px !important;
          padding-left: 0;
          li {
            padding: 0 !important;
            position: relative;
            padding-left: 25px !important;
            &:after {
              content: "";
              position: absolute;
              width: 7px;
              height: 7px;
              background: var(--primary-dark-color) !important;
              border-radius: 100px;
              left: 6px;
              top: 5px;
            }
            + li {
              margin-top: 2px;
            }
          }
        }
        + .content {
          margin-top: 25px;
          @media (max-width: 767px) {
            margin-top: 16px;
          }
        }
        div.tag {
          display: flex;
          padding: 0;
          margin: 0px 0 0 0 !important;
          span {
            margin: 0 4px 4px 0;
            border-radius: 100px;
            background-color: #e5eaf7;
            display: flex;
            min-width: 72px;
            padding: 6px 10px 3px;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 15px;
            text-align: center;
          }
        }
      }
    }
    &__summary {
      margin-top: 15px;
      &__header {
        margin: 3px 0 0 0;
        font-family: "TTCommons-Medium";
        font-size: 22px;
        line-height: 24px;
        color: var(--primary-dark-color);
      }
      &__body {
        margin: 6px 0 0 0;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        line-height: 21px;
        color: var(--primary-dark-color);
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 15px;
      &__posted {
        margin: 4px 0 0 0;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        line-height: 19px;
        color: var(--gray-text-color);
      }
    }
  }
}
