@import "../../../../styles/colors.scss";
@import "../../../../styles/typographies.scss";
@import "../../../../styles/animations.scss";

.group-profile__dropdown-button {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 0;
  width: 100%;
  span,
  a {
    box-shadow: none;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background-color: var(--icon_background);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      background-color: var(--icon_background_hover);
    }
    &:focus {
      box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    }
  }
}
.group-profile__dropdown {
  @include fadeInAnimation(0.4s);
  list-style: none;
  box-shadow: var(--card-box-shadow);
  border-radius: 10px 1px 10px 10px;
  padding: 0 20px;
  margin: 8px 0 0 0;
  width: 265px;
  position: absolute;
  z-index: 99;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  hr {
    height: 1px;
    background-color: $color-grey;
    border: none;
    margin: 0;
    width: 80%;
    margin: auto;
  }
  > a {
    margin: 0px;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    color: var(--primary-dark-color);
    text-align: center;
    padding: 22px 0 18px;
    display: block;
    text-decoration: none;
    cursor: pointer;
    span {
      border-bottom: 2px solid transparent;
    }
    + a {
      border-top: 2px solid #eaeef3;
    }
    &:hover {
      span {
        border-bottom-color: var(--primary-dark-color);
      }
    }
    &:focus {
      span {
        border-bottom-color: var(--primary-dark-color);
      }
    }
  }
}
