.top-recommended-jobs {
  margin-top: 5px;
  &__header {
    margin-bottom: 15px;
    display: flex;
    gap: 12px;
    &__title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      word-break: break-word;
      margin: 0 0 0 0;
      display: flex;
      a {
        margin: 0 0 0 10px;
      }
      &__redirect-text {
        cursor: pointer;
        border-bottom: 1px solid var(--primary-dark-color);
        &:hover {
          color: var(--link-color);
          border-bottom: 1px solid var(--link-color);
        }
      }
    }
    &__view-all {
      font-size: 18px;
      line-height: 16px;
      color: var(--link-color);
      font-family: "TTCommons-Medium";
      font-weight: 500;
      text-decoration: none;
      border-bottom: 2px solid var(--link-color) !important;
      cursor: pointer;
      margin: 2px 0 0 0;
    }
  }
  &__listing {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &__item {
      &__card {
        > div {
          padding: 18px 15px !important;
        }
        &:hover {
          .top-recommended-jobs__listing__item__job__actions {
            opacity: 1;
            pointer-events: initial;
          }
        }
      }
      &__tags {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        row-gap: 10px;
        margin-bottom: 10px;
        &__item {
          border-radius: 100px;
          background-color: #f1f4f7;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 21px;
          text-align: center;
          padding: 4px 10px 0;
          display: inline-block;
          white-space: nowrap;
        }
      }
      &__job {
        display: flex;
        align-items: center;
        gap: 15px;
        &__avatar {
          > div {
            > div {
              width: 50px;
              height: 50px;
            }
          }
        }
        &__tooltip {
          p {
            margin: 0 0 0 0;
          }
          &__line-one {
            margin: 0 0 10px 0 !important;
          }
        }
        &__content {
          flex-grow: 1;
          &__title {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            line-height: 18px;
            font-weight: 500;
            margin: 0 0 0 0;
          }
          &__company {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            line-height: 19px;
            font-weight: 400;
            margin: 0 0 0 0;
          }
          &__location {
            font-family: "TTCommons-Regular";
            color: var(--f-light-gray-color);
            font-size: 15px;
            line-height: 20px;
            font-weight: 400;
            margin: 0 0 0 0;
          }
        }
        &__actions {
          min-width: 110px;
          opacity: 0;
          pointer-events: none;
          display: flex;
          align-items: center;
          gap: 8px;
          &__apply {
            display: block;
            font-family: "TTCommons-Medium";
            background-color: var(--secondary-color) !important;
            color: #fff !important;
            font-size: 18px;
            padding: 7px 8px 3px;
            text-decoration: none;
            text-align: center;
            border-radius: 100px;
            min-width: 80px;
            min-height: 32px;
            transition: opacity 0.3s ease-in;
          }
          &--accessible {
            opacity: 1;
            pointer-events: initial;
          }
        }
      }
      &__no-job-text {
        text-align: center;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        margin: 0 0 0 0;
        padding: 12px 15px 10px 15px;
      }
    }
  }
}
