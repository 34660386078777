.feedback-dialog {
  .MuiPaper-root {
    width: 100%;
    max-width: 480px;
  }
  .MuiDialogContent-root {
    padding: 30px 28px;
    box-sizing: border-box;
    width: 100% !important;
    .MuiTypography-root {
      padding: 0 !important;
      margin: 0;
    }
    .modal-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      + div {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        margin: 8px auto 0;
        max-width: 100%;
      }
    }
    .closeModal {
      margin: 0 !important;
    }
    .custom-rating {
      > div {
        display: flex !important;
        justify-content: center;
        > span {
          margin-left: 8px;
          gap: 8px;
        }
      }
      .star {
        width: 52px !important;
        height: 52px !important;
        margin: 0 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .model-body {
    margin: 16px auto 0;
    .rating-box {
      text-align: center;
      margin: 0 0 18px;
      @media (max-width: 1024px) {
        margin: 0 0 16px;
      }
    }
    .sub-header-primary {
      color: var(--field-label-color);
      margin: 0 0 4px;
    }
    .about-box {
      margin: 0 0 20px;
      @media (max-width: 1024px) {
        margin: 0 0 16px;
      }
      label.form-label {
        margin-top: 0;
        margin-bottom: 7px;
      }
      .MuiOutlinedInput-multiline {
        padding: 0;
        margin: 0;
      }

      fieldset {
        display: none;
      }
    }
    .friend-refer {
      .sub-header-primary {
        text-align: center;
        justify-content: center;
        padding: 5px 0 0 0;
        line-height: 12px;
        margin: 0 0 15px 0px;
        font-size: 15px;
        color: var(--field-label-color);
        font-family: "TTCommons-Regular";
      }
      div.points-rating-wrapper {
        span.MuiRating-root {
          display: flex;
          margin: 0;
          padding: 0 0 4px 0;
          list-style: none;
          justify-content: space-between;
          width: 100%;
          label {
            text-align: center;
          }
          span.point {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            border: 0;
            border-radius: 100px;
            line-height: 35px;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
            margin: 0 0 6px;
            background-color: var(--icon_background);
            text-decoration: none;
            &.active {
              background: var(--primary-dark-color) !important;
              color: #fff !important;
            }
          }
        }
      }
    }
    .like {
      display: flex;
      justify-content: space-between;
      margin: 0 0 30px;
      @media (max-width: 1024px) {
        margin: 0 0 16px;
      }
      .sub-header-primary {
        margin: 0;
        font-size: 15px;
        line-height: 15px;
        text-align: left;
        color: var(--primary-dark-color);
      }
      .text-right {
        text-align: right;
      }
    }
    .file-attch {
      display: flex;
      margin: 0 0 30px;
      flex-wrap: wrap;
      justify-content: center;
      @media (max-width: 1024px) {
        margin: 0 0 16px;
      }
      p {
        width: 100%;
        text-align: center;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        line-height: 20px;
      }
    }
    .feedback-reply {
      margin: 14px 0px 15px 0px;
      .sub-header-primary {
        color: var(--gray-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
    .btn {
      text-align: center;
      margin: 0 0 30px;
      @media (max-width: 1024px) {
        margin: 0 0 16px;
      }
      button {
        max-width: 238px;
        &.got-it {
          /* height: 30px; */
          width: 124px;
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
          padding: 0.75rem 30px 8px;
          height: auto;
        }
      }
    }
    .thankyou-btn {
      margin: 0;
    }
  }
  &.feedback-dialog-meet-page {
    .model-body {
      margin-top: 0;
      .view-profile-card {
        padding: 0;
        box-shadow: none;
        margin-bottom: 0;
        .meeting-location {
          text-align: center;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          margin-top: 8px;
          flex-wrap: wrap;
          img {
            width: 25px;
            margin: 0 auto 6px;
            vertical-align: middle;
          }
          span {
            width: 100%;
            display: block;
          }
          p {
            width: 100%;
            margin: 0;
          }
          .zoom-link {
            color: var(--primary-dark-color);
            font-size: 15px;
            font-weight: 500;
            -webkit-letter-spacing: 0;
            -moz-letter-spacing: 0;
            -ms-letter-spacing: 0;
            letter-spacing: 0;
            line-height: 15px;
            font-family: "TTCommons-Medium";
            border-bottom: 2px solid var(--primary-dark-color);
            cursor: pointer;
          }
        }
      }
    }
  }
  .report-modal {
    margin-top: 25px;
    &__textbox {
      > div {
        margin-bottom: 0;
      }
      .form-label {
        margin: 0 0 10px 4px !important;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        padding: 5px 0 0 0 !important;
      }
      .MuiInputBase-multiline {
        padding: 0;
        margin-bottom: 0;
        textarea {
          border: 2px solid var(--gray-outline-color);
          border-radius: 10px;
          background-color: #ffffff;
          padding: 21px 20px;
          box-sizing: border-box;
          font-size: 18px;
          line-height: 22px;
          font-family: "TTCommons-Regular", "Apple Color Emoji",
            "NotoColorEmoji-Regular";
          color: var(--primary-dark-color);
          max-height: 100px;
          overflow: auto !important;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: var(--field-label-color) !important;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: var(--field-label-color) !important;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: var(--field-label-color) !important;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: var(--field-label-color) !important;
          }
          // --Focus style
          &:focus {
            border-color: var(--gray-color) !important;
          }
          &:hover {
            border-color: #bfced8;
          }
          &:focus {
            border-color: var(--gray-color);
          }
          /* scroll styling start */
          scrollbar-width: thin !important;
          scrollbar-color: var(--light-gray-color) transparent; /* scroll thumb and track */
          &::-webkit-scrollbar {
            width: 7px; /* width of the entire scrollbar */
            transition: all ease-in-out 500ms;
          }
          &::-webkit-scrollbar-track {
            background: transparent !important; /* color of the tracking area */
            border-radius: 50px;
            width: 7px;
            -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
              29px 0px;
            margin: 10px 0 10px 0;
          }
          &::-webkit-scrollbar-thumb {
            background-color: var(
              --light-gray-color
            ) !important; /* color of the scroll thumb */
            border-radius: 50px; /* roundness of the scroll thumb */
            height: 40px;
          }
          &::-webkit-scrollbar-corner {
            border-radius: 50%;
          }
          &::-webkit-scrollbar-thumb:hover {
            border: 0;
            background-color: var(
              --gray-color
            ) !important; /* color of the scroll thumb */
          }
          /* scroll styling end */
        }
      }
    }
    &__button {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
